body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

div#root {
  min-width: 900px;
}

@media print {
  @page {
    size: auto;
    margin: 0;
  }
}

.no-wrap {
  white-space: nowrap;
}

.app-loading {
  margin: auto;
  padding: 10px;
}